<template>
  <router-link
    :to="{
      name: 'TaskPage',
      params: {
        teamId: task.progress.teamId,
        taskId: task.progress.taskId
      }
    }"
    :key="task.id"
    class="task flex flex-row w-full justify-start cursor-pointer border py-5 lg:py-6 px-4 pl-6 md:pl-10 rounded-md bg-white relative"
  >
    <div
      class="w-10 md:w-16 flex justify-start items-center flex-shrink-0 absolute top-2 left-2 "
    >
      <p class="text-xs text-gray-500 md:text-xl">#{{ position }}</p>
    </div>
    <div
      v-if="task.coverImage"
      class="image
             rounded-full
             w-20
             h-20
             relative
             bg-no-repeat
             bg-center
             bg-cover
             flex-shrink-0
             overflow-hidden"
      :style="backgroundImage"
    ></div>
    <div
      v-else
      class="image
             rounded-full
             w-20
             h-20
             relative
             bg-no-repeat
             bg-center
             bg-cover
             flex-shrink-0
             text-sm
             flex
             justify-center
             items-center
             overflow-hidden"
      :class="{ 'border rounded-full text-xs': !task.coverImage }"
    >
      <p v-if="!task.coverImage">
        {{ $t("message.no-image") }}
      </p>
    </div>
    <div class="flex flex-col md:flex-row pl-4 md:pl-10 w-full">
      <div class="lex flex-col flex-grow flex justify-center">
        <h2 class="text-sm md:text-lg">
          {{ task.coverContent }}
        </h2>
      </div>
      <div class="flex flex-row gap-2 md:items-end flex-shrink-0 mt-2">
        <div
          class="flex flex-col justify-center items-center"
          v-if="task.taskType === 'DonorboxTask'"
        >
          <span class="text-xs md:text-base">{{ $t("message.received") }}</span>
          <p class="text-xs md:text-base">
            {{ task.donationsTotal | formatCurrencyDonorbox }}
          </p>
        </div>
        <div class="flex flex-col justify-center items-center">
          <LivesImpactedPill
            :text="formatPointsOnCompletion(task.pointsAwarded)"
            class="mb-4 mx-auto"
            :showInfo="false"
          />
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
import { formatPointsOnCompletion } from "@/utils/basicHelpers.js";
import defaultCurrency from "@/constants/defaultCurrency.js";
import LivesImpactedPill from "@/components/shared/LivesImpactedPill.vue";

export default {
  name: "TaskRankingLink",
  components: {
    LivesImpactedPill
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    position: {
      type: Number,
      required: true
    }
  },
  computed: {
    backgroundImage() {
      if (!this.task.coverImage || this.task.coverImage.url === "") return "";

      return `background-image: url('${this.task.coverImage.url}')`;
    }
  },
  methods: {
    formatPointsOnCompletion,
    getPointsText(task) {
      if (task.taskType === "DonationTask") {
        return `${defaultCurrency.pointsPerUnit.short} per ${defaultCurrency.symbol}`;
      } else if (task.taskType === "DonorboxTask") {
        return `${defaultCurrency.pointsPerUnit.short} per ${defaultCurrency.symbol}`;
      } else {
        return this.formatPointsOnCompletion(task.pointsOnCompletion) || 0;
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
.bg-shadow {
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 0%,
    rgb(0 0 0 / 94%) 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.image {
  aspect-ratio: 1/1;
}

h2 {
  @apply m-0;

  font-size: 14px;
  line-height: initial;
}
h3 {
  @apply m-0;

  font-size: 13px;
  line-height: initial;
}
h2,
h3 {
  word-break: break-word;
}
</style>
